<template>
<aheader />
<section class="section">
  <div class="columns">
    <div class="column is-8 is-offset-2">
      Step
      <span class="icon has-text-success is-big">
        <i class="fa-solid fa-3 fa-lg"></i>
      </span>
      <progress class="progress is-success" value="60" max="100"></progress>
    </div>
  </div>
  <section class="section">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="title is-6 has-text-left mb-2">
          お客さまの情報・注文商品の確認
        </div>
        <div class="box">
          <div class="title is-4">
            お客さまの情報
          </div>
          <div class="columns">
            <div class="column is-one-quarter is-offset-2">
              <div class="has-text-left">
                お名前
              </div>
            </div>
            <div class="column is-three-quarter">
              <div class="has-text-left mt-3 is-size-6">
                {{$store.state.order_customer.kanaFamily}} {{$store.state.order_customer.kanaFirst}}
              </div>
              <div class="has-text-left is-size-5">
                  {{$store.state.order_customer.familyName}} {{$store.state.order_customer.firstName}} 様
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-one-quarter is-offset-2">
              <div class="has-text-left">
                お届け先住所
              </div>
            </div>
            <div class="column is-three-quarter">
              <div class="has-text-left">
                <span class="has-text-right mt-3 is-size-6">
                  〒 {{$store.state.order_customer.postCode}}
                </span>
              </div>
              <div class="has-text-left is-size-5">
                <span class="has-text-right ml-3">
                  {{$store.state.order_customer.address}}
                </span>
              </div>
            </div>
          </div>
          <div class="columns mb-4">
            <div class="column is-one-quarter is-offset-2">
              <div class="has-text-left">
                電話番号
              </div>
            </div>
            <div class="column is-three-quarter">
              <div class="has-text-left is-size-5">
                {{$store.state.order_customer.tel}}
              </div>
            </div>
          </div>
          <div class="columns mb-4">
            <div class="column is-one-quarter is-offset-2">
              <div class="has-text-left">
                メールアドレス
              </div>
            </div>
            <div class="column is-three-quarter">
              <div class="has-text-left is-size-5">
                {{$store.state.order_customer.eMail}}
              </div>
            </div>
          </div>
          <hr>
          <div class="title is-4">
            ご注文の品
          </div>
          <div class="columns">
            <div class="column is-9 is-offset-2">
              <div v-for="item, index in this.$store.state.cartList" :key="index">
                <div class="columns">
                  <div class="column is-2">
                    <p class="image is-64x64">
                      <img :src="item.icon">
                    </p>
                  </div>
                  <div class="column is-10">
                    <div class="columns">
                      <div class="column is-7 is-offset-1">
                        <p class="title is-5 has-text-left">
                          {{item.name}}
                        </p>
                        <p class="subtitle is-6 has-text-left ml-5">
                          {{item.kind}}
                        </p>
                      </div>
                      <div class="column is-4">
                        <p class="has-text-left">
                          {{item.value}}円&emsp;{{item.count}}個
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="title is-4">
            お会計
          </div>
          <div class="title is-6">
            円
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="columns">
    <div class="column is-3 is-offset-2">
      <button class="button has-background-info-dark has-text-white-ter" @click="pushPage('order_customer')">
        <span class="icon is-small">
          <i class="fa-solid fa-arrow-left"></i>
        </span>
        <span>
          前に戻る
        </span>
      </button>
    </div>
    <div class="column is-3 is-offset-2">
      <button class="button has-background-info-dark has-text-white-ter" @click="pushPage('22')">
        <span>
          次にすすむ
        </span>
        <span class="icon is-small">
          <i class="fa-solid fa-arrow-right"></i>
        </span>
      </button>
    </div>
  </div>
</section>
<afooter/>
</template>
<script>
export default {
  data() {
    return {
      familyName: "山田",
      firstName: "太郎",
      kanaFamily: "ヤマダ",
      kanaFirst: "タロウ",
      postCode: "000-0000",
      address: "兵庫県神戸市西区８－４－５",
      eMail: "test@test.com"
    }
  },
  mounted() {
    this.scrollTop();
  },
  computed: {
    yenValue(index) {
      let value = this.$store.state.cartList[index].value
      const yenValue = new Intl.NumberFormat().format(value)
      return yenValue
    }
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    pushPage(page) {
      this.$router.push(page);
    }
  }
}
</script>
